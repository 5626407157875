import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import logoUrl from "../../assets/logo.jpg";
import styles from "./styles.module.scss";

const Header: React.FC = () => {
  const navigate = useNavigate();

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <img
          src={logoUrl}
          alt="Logo"
          className={styles.logo}
          onClick={() => navigate("/")}
        />

        <div className={styles.links}>
          <Link to="/news">Noticias</Link>
        </div>

        <div className={styles.right}>
          <a href="mailto:domaconcursos@gmail.com">domaconcursos@gmail.com</a>
        </div>
      </div>
    </header>
  );
};

export { Header };
