import styles from "./styles.module.scss";

interface LoaderProps {
  size?: "small" | "medium";
  className?: string;
  color?: string;
}

const Loader: React.FC<LoaderProps> = ({ size, color, className }) => {
  return (
    <div
      className={`${styles.loader} ${
        size === "small" ? styles.loaderSmall : styles.loaderMedium
      } ${className}`}
      style={{ borderColor: color, borderRightColor: "transparent" }}
    />
  );
};

export { Loader };
