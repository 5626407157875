import styles from "./styles.module.scss";

interface TableComponents {
  TH: React.FC<JSX.IntrinsicElements["th"]>;
  TD: React.FC<JSX.IntrinsicElements["td"]>;
  TR: React.FC<JSX.IntrinsicElements["tr"]>;
  TBody: React.FC<JSX.IntrinsicElements["tbody"]>;
  THead: React.FC<JSX.IntrinsicElements["thead"]>;
}

interface TableProps {
  hoverable?: boolean;
  responsive?: boolean;
  children?: React.ReactNode;
}

const Table: React.FC<TableProps> & TableComponents = ({
  hoverable,
  responsive,
  children,
}) => {
  return (
    <div className={responsive ? styles.tableResponsive : ""}>
      <table className={`${styles.table} ${hoverable && styles.tableHover}`}>
        {children}
      </table>
    </div>
  );
};

Table.TH = ({ children, ...props }) => {
  return <th {...props}>{children}</th>;
};

Table.TD = ({ children, ...props }) => {
  return <td {...props}>{children}</td>;
};

Table.TR = ({ children, ...props }) => {
  return <tr {...props}>{children}</tr>;
};

Table.TBody = ({ children, ...props }) => {
  return <tbody {...props}>{children}</tbody>;
};

Table.THead = ({ children, ...props }) => {
  return <thead {...props}>{children}</thead>;
};

export { Table };
