import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import CalendarIcon from "../../assets/calendar";
import LocationIcon from "../../assets/location";
import { Button, Col, Container, Loader, Modal, Row } from "../../components";
import { CompetitionRegister } from "../../containers";
import { firestore } from "../../firebase";
import { Competition } from "../../types";
import { dayjs, formatDateRange, parseCompetition } from "../../utils";
import styles from "./styles.module.scss";

const CompetitionPage: React.FC = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [competition, setCompetition] = useState<Competition>();
  const [showInscription, setShowInscription] = useState(false);

  useEffect(() => {
    if (!id) return;
    setIsLoading(true);

    const unsubscribe = onSnapshot(
      doc(firestore, "competitions", id),
      (doc) => {
        setCompetition(parseCompetition(doc));
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [id]);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <Row>
          <Col className={styles.col4}>
            <Container>
              <div className={styles.info}>
                <div className={styles.name}>{competition?.name}</div>
                <div className={styles.date}>
                  <CalendarIcon className={styles.icon} />{" "}
                  {formatDateRange(competition?.date, competition?.dateEnd)}
                </div>
                <div className={styles.location}>
                  <LocationIcon className={styles.icon} />{" "}
                  {competition?.location}
                </div>

                {competition?.image ? (
                  <>
                    <div className={styles.poster}>Cartel</div>
                    <img
                      src={competition.image.url}
                      className={styles.image}
                      alt={competition.name}
                    />
                  </>
                ) : null}
              </div>
            </Container>
          </Col>
          <Col>
            <Container>
              <div className={styles.sectionTitle}>Información</div>
              {competition?.avance ? (
                <div>
                  <a
                    className={styles.link}
                    href={competition.avance.url}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    Ver avance
                  </a>
                </div>
              ) : null}

              {competition?.boxes ? (
                <div>
                  <a
                    className={styles.link}
                    href={competition.boxes.url}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    Ver boxes
                  </a>
                </div>
              ) : null}

              {competition?.exitOrder ? (
                <div>
                  <a
                    className={styles.link}
                    href={competition.exitOrder.url}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    Ver orden de salida
                  </a>
                </div>
              ) : null}

              {competition?.exitOrder2 ? (
                <div>
                  <a
                    className={styles.link}
                    href={competition.exitOrder2.url}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    Ver orden de salida
                  </a>
                </div>
              ) : null}
            </Container>

            {competition?.inscriptionsEnabled ? (
              <Container>
                <div className={styles.sectionTitle}>
                  <div>Inscripciones</div>

                  {dayjs().isSameOrBefore(
                    dayjs.utc(competition.inscriptionsEnd)
                  ) ? (
                    <div>
                      <Button
                        type="success"
                        onClick={() => setShowInscription(true)}
                      >
                        Inscribirse
                      </Button>
                    </div>
                  ) : null}
                </div>

                <div>
                  <a href={`/competition/${id}/participants`}>Inscritos</a>
                </div>
              </Container>
            ) : null}

            <Container>
              <div className={styles.sectionTitle}>Resultados</div>
              {competition?.results?.length ? (
                competition.results.map((item, index) => (
                  <div>
                    <a
                      key={index}
                      className={styles.link}
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.name}
                    </a>
                  </div>
                ))
              ) : !competition?.resultCategories?.length ? (
                <div className={styles.noData}>
                  Todavía no hay ningún resultado publicado
                </div>
              ) : null}

              {competition?.resultCategories?.map((category) => (
                <div>
                  <a
                    key={category.id}
                    className={styles.link}
                    href={`/competition/${id}/results/${category.id}`}
                  >
                    {category.name}
                  </a>
                </div>
              ))}
            </Container>
          </Col>
        </Row>
      )}

      <Modal
        isOpen={showInscription}
        title="Inscripción"
        onClickClose={() => setShowInscription(false)}
      >
        <CompetitionRegister
          competition={competition}
          onRegister={() => setShowInscription(false)}
        />
      </Modal>
    </div>
  );
};

export { CompetitionPage };
