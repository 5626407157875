import { collection, getDocs, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Container, Loader, Table } from "../../components";
import { firestore } from "../../firebase";
import styles from "./styles.module.scss";

interface Participant {
  category: string;
  categoryName: string;
  competitionId: string;
  horseName: string;
  name: string;
}

const CompetitionList: React.FC = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [participants, setParticipants] = useState<Participant[]>([]);

  useEffect(() => {
    if (!id) return;
    setIsLoading(true);

    const loadParticipants = async () => {
      const q = query(
        collection(firestore, "competitions", id, "participants")
      );
      const docs = await getDocs(q);

      const paricipants: Participant[] = [];
      docs.forEach((doc) => {
        paricipants.push(doc.data() as Participant);
      });

      setIsLoading(false);
      setParticipants(paricipants);
    };

    loadParticipants();
  }, [id]);

  return (
    <div className={styles.container}>
      <Container>
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        ) : (
          <>
            <div className={styles.sectionTitle}>Inscritos</div>
            {participants.length ? (
              <div className={styles.usersTable}>
                <Table responsive>
                  <Table.THead>
                    <Table.TR>
                      <Table.TH>Nombre y apellidos</Table.TH>
                      <Table.TH>Nombre del caballo</Table.TH>
                      <Table.TH>Categoría</Table.TH>
                    </Table.TR>
                  </Table.THead>
                  <Table.TBody>
                    {participants.map((item, index) => (
                      <Table.TR key={index}>
                        <Table.TD>{item.name}</Table.TD>
                        <Table.TD>{item.horseName}</Table.TD>
                        <Table.TD>{item.categoryName}</Table.TD>
                      </Table.TR>
                    ))}
                  </Table.TBody>
                </Table>
              </div>
            ) : (
              <div className={styles.emptyState}>
                Todavía no hay ningún participante
              </div>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export { CompetitionList };
