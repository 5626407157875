import { collection, getDocs, query } from "firebase/firestore";
import { useQuery } from "react-query";
import sanitizeHtml from "sanitize-html";
import { firestore } from "../../firebase";

import dayjs from "dayjs";
import { Container } from "../../components";
import styles from "./styles.module.scss";

const defaultOptions: sanitizeHtml.IOptions = {
  allowedTags: [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "div",
    "ol",
    "ul",
    "li",
    "sub",
    "sup",
    "blockquote",
    "b",
    "i",
    "u",
    "s",
    "em",
    "strong",
    "a",
    "p",
    "br",
    "span",
    "img",
  ],
  allowedAttributes: {
    a: ["href"],
    span: ["class"],
    img: ["src"],
    div: ["class"],
  },
  selfClosing: ["img", "br"],
  allowedSchemes: ["data", "http"],
};

const sanitize = (dirty: string) => ({
  __html: sanitizeHtml(dirty, defaultOptions),
});

const NewsPage: React.FC = () => {
  const { data: news } = useQuery(["news"], async () => {
    const newsRef = query(collection(firestore, "news"));
    const news = await getDocs(newsRef);

    return news.docs.map((doc) => ({
      id: doc.id,
      title: doc.get("title"),
      description: doc.get("description"),
      date: doc.get("date").toDate(),
    }));
  });

  return (
    <div className={styles.container}>
      {news?.map((news) => (
        <Container key={news.id} className={styles.cont}>
          <div className="">
            <h2>{news.title}</h2>
            <div className={styles.date}>
              {dayjs(news.date).format("DD/MM/YYYY HH:mm")}
            </div>

            <div
              className={styles.description}
              dangerouslySetInnerHTML={sanitize(news.description)}
            />
          </div>
        </Container>
      ))}
    </div>
  );
};

export { NewsPage };
