import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CalendarIcon from "../../assets/calendar";
import ChevronDownIcon from "../../assets/chevron-down";
import { Competition } from "../../types";
import { formatDateRange } from "../../utils";
import styles from "./styles.module.scss";

interface AccordionProps {
  title: string;
  active?: boolean;
  competitions: Competition[];
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  competitions,
  active,
}) => {
  const [isActive, setIsActive] = useState(active);
  const [height, setHeight] = useState(0);

  const contentContainer = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (contentContainer.current) {
      setHeight(isActive ? contentContainer.current.scrollHeight : 0);
    }
  }, [isActive]);

  return (
    <div className={styles.accordion}>
      <div
        className={`${styles.header} ${isActive ? styles.active : ""}`}
        onClick={() => setIsActive(!isActive)}
      >
        {title}
        <ChevronDownIcon
          className={`${styles.icon} ${isActive ? styles.active : ""}`}
        />
      </div>

      <div
        className={styles.content}
        style={{ maxHeight: `${height}px` }}
        ref={contentContainer}
      >
        {competitions.map((item) => (
          <div
            className={styles.competitionLink}
            onClick={() => navigate(`/competition/${item.id}`)}
            key={item.id}
          >
            {item.name}
            <div className={styles.date}>
              <CalendarIcon className={styles.icon} />{" "}
              {formatDateRange(item.date, item.dateEnd)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { Accordion };
