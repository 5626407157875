import { DocumentData } from "firebase/firestore";
import { Competition } from "../types";
import { dayjs } from "./dayjs";

const parseCompetition = (doc: DocumentData): Competition => {
  return {
    ...(doc.data() as Competition),
    date: dayjs.utc(doc.get("date").toDate()).format("YYYY-MM-DD"),
    dateEnd: doc.get("dateEnd")
      ? dayjs.utc(doc.get("dateEnd").toDate()).format("YYYY-MM-DD")
      : undefined,
    inscriptionsEnd: dayjs
      .utc(doc.get("inscriptionsEnd")?.toDate())
      .format("YYYY-MM-DD HH:mm"),
    category: doc.get("category")?.id,
    managers: doc.get("managers")?.map((item: any) => ({
      ...item,
      from: dayjs.utc(item.from.toDate()).format("YYYY-MM-DD HH:mm"),
      to: dayjs.utc(item.to.toDate()).format("YYYY-MM-DD HH:mm"),
    })),
    resultCategories: doc.get("resultCategories"),
    id: doc.id,
  };
};

export { parseCompetition };
