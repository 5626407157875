import { collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ChevronForward from "../../assets/chevron-forward";
import { firestore } from "../../firebase";
import { Category } from "../../types";
import style from "./styles.module.scss";

const Home: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const q = query(collection(firestore, "categories"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cat: Category[] = [];
      querySnapshot.forEach((doc) => {
        cat.push({ id: doc.id, ...doc.data() } as Category);
      });

      setCategories(cat);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className={style.container}>
      <div className={style.categories}>
        {categories
          .sort((a, b) => a.order - b.order)
          .map((category) => (
            <div
              key={category.id}
              className={style.col}
              onClick={() => navigate(`/category/${category.id}`)}
            >
              <div
                className={style.horseCategory}
                style={{
                  backgroundImage: `url(${category.image})`,
                }}
              >
                <div className={style.title}>{category.name}</div>
                <div className={style.viewResults}>
                  Ver resultados <ChevronForward className={style.icon} />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export { Home };
