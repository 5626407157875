import { ResultCategory } from "../types";

export interface UserResult {
  id: string;
  category: string;
  competitionId: string;
  exitOrder: string;
  horse: string;
  name: string;
  penalty: number;
  scores: {
    judge1: number;
    judge2: number;
    judge3: number;
  }[];
}

export interface ParsedResult {
  id: string;
  exitOrder: string;
  name: string;
  horse: string;
  judge1: {
    score: number;
    position: number;
  };
  judge2?: {
    score: number;
    position: number;
  };
  judge3?: {
    score: number;
    position: number;
  };
  average: number;
  penalty: number;
  percentage: number;
}

const ranking = (arr: number[]) => {
  const sorted = [...arr].sort((a, b) => b - a);
  return arr.map((x) => sorted.indexOf(x) + 1);
};

const parseResult = (
  userResult: UserResult[],
  category: ResultCategory
): ParsedResult[] => {
  const maxScore = category.maxScore;

  const scores1 = userResult.map(
    (item) =>
      (item.scores.reduce((partialSum, a) => partialSum + a.judge1, 0) * 100) /
      maxScore
  );

  let scores2: number[] | undefined;
  if (category.judge2) {
    scores2 = userResult.map(
      (item) =>
        (item.scores.reduce((partialSum, a) => partialSum + a.judge2, 0) *
          100) /
        maxScore
    );
  }

  let scores3: number[] | undefined;
  if (category.judge3) {
    scores3 = userResult.map(
      (item) =>
        (item.scores.reduce((partialSum, a) => partialSum + a.judge3, 0) *
          100) /
        maxScore
    );
  }

  const ranking1 = ranking(scores1);

  let ranking2: number[] | undefined;
  if (scores2) {
    ranking2 = ranking(scores2);
  }

  let ranking3: number[] | undefined;
  if (scores3) {
    ranking3 = ranking(scores3);
  }

  return userResult.map((item, index) => {
    const score1 = scores1[index];
    const score2 = scores2 ? scores2[index] : 0;
    const score3 = scores3 ? scores3[index] : 0;

    const totalJudges = ranking2 && ranking3 ? 3 : ranking2 ? 2 : 1;

    const average = (score1 + score2 + score3) / totalJudges;

    const result: ParsedResult = {
      id: item.id,
      exitOrder: item.exitOrder,
      name: item.name,
      horse: item.horse,
      judge1: {
        score: score1,
        position: ranking1[index],
      },
      average,
      penalty: item.penalty,
      percentage: average - item.penalty,
    };

    if (ranking2) {
      result.judge2 = {
        score: score2,
        position: ranking2[index],
      };
    }

    if (ranking3) {
      result.judge3 = {
        score: score3,
        position: ranking3[index],
      };
    }

    return result;
  });
};

export { parseResult };
