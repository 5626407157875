import styles from './styles.module.scss';

interface RowProps {
  className?: string;
  children?: React.ReactNode;
}

const Row: React.FC<RowProps> = ({ className, children }) => {
  return <div className={`${styles.row} ${className}`}>{children}</div>;
};

export { Row };
