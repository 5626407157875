import { Link } from "react-router-dom";
import style from "./styles.module.scss";

const NotFound: React.FC = () => {
  return (
    <div className={style.notfound}>
      <h1>Error 404</h1>
      <p>That page doesn&apos;t exist.</p>
      <Link to="/">
        <h4>Back to Home</h4>
      </Link>
    </div>
  );
};

export { NotFound };
