import React from "react";
import { Route, Routes } from "react-router-dom";
import { Header } from "./components";
import {
  CategoryPage,
  CompetitionList,
  CompetitionPage,
  CompetitionResult,
  Home,
  NewsPage,
  NotFound,
} from "./routes";

const App: React.FC = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/category/:id" element={<CategoryPage />} />
        <Route path="/competition/:id" element={<CompetitionPage />} />
        <Route
          path="/competition/:id/participants"
          element={<CompetitionList />}
        />
        <Route
          path="/competition/:id/results/:categoryId"
          element={<CompetitionResult />}
        />
        <Route element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
