import { dayjs } from "./dayjs";

const formatDateRange = (dateStart?: string, dateEnd?: string) => {
  if (!dateStart) return;

  const start = dayjs(dateStart).startOf("day");

  if (!dateEnd) return start.format("dddd D [de] MMMM, YYYY");

  const end = dayjs(dateEnd).startOf("day");

  if (start.isSame(end)) {
    return start.format("dddd D [de] MMMM, YYYY");
  }

  let currentDate = start.clone();

  const ranges: dayjs.Dayjs[] = [];

  while (currentDate.isSameOrBefore(end)) {
    ranges.push(currentDate);
    currentDate = currentDate.add(1, "day");
  }

  if (ranges.length === 2) {
    const [twoStart, twoEnd] = ranges;

    if (twoStart.get("month") === twoEnd.get("month")) {
      return `${twoStart.get("date")} y ${twoEnd.get(
        "date"
      )} de ${twoStart.format("MMMM")} del ${twoStart.get("year")}`;
    }

    return `${twoStart.format("DD [de] MMMM")} y ${twoEnd.format(
      "DD [de] MMMM"
    )} del ${twoStart.get("year")}`;
  } else if (ranges.length === 3) {
    const [threeStart, threeMiddle, threeEnd] = ranges;

    if (
      threeStart.get("month") === threeMiddle.get("month") &&
      threeStart.get("month") === threeEnd.get("month")
    ) {
      return `${threeStart.get("date")}, ${threeMiddle.get(
        "date"
      )} y ${threeEnd.get("date")} de ${threeStart.format(
        "MMMM"
      )} del ${threeStart.get("year")}`;
    }
  }

  return `Del ${start.format("DD [de] MMMM")} al ${end.format(
    "DD [de] MMMM"
  )} del ${start.get("year")}`;
};

export { formatDateRange };
