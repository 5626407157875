import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { ErrorOption, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Alert, Button, FormGroup, Select, TextInput } from "../../components";
import { functions } from "../../firebase";
import { Competition } from "../../types";
import styles from "./styles.module.scss";

interface InputError extends ErrorOption {
  name: string;
}

type Inputs = {
  name: string;
  license: string;
  horseName: string;
  horseLicense: string;
  category: string;
  email: string;
  regaCode: string;
  licNumber: string;
  club: string;
  phone: string;
};

const inputsErrors: { [key: string]: string } = {
  name: "Tu nombre y apellidos no pueden estar vacíos",
  license: "El número de licencia no puede estar vacío",
  horseName: "El nombre del caballo no puede estar vacío",
  horseLicense: "El número de licencia del caballo no puede estar vacío",
  category: "La categoría de la competición no puede estar vacía",
  email: "Tu correo electrónico no puede estar vacío",
  regaCode: "El código rega no puede estar vacío",
  licNumber: "El número de lic no puede estar vacío",
  club: "El nombre del club no puede estar vacío",
  phone: "Tu número de teléfono no puede estar vacío",
};

const getErrors = (data: Inputs) => {
  const inputError: InputError[] = [];
  Object.keys(inputsErrors).forEach((key) => {
    if (key in data && !data[key as keyof Inputs]) {
      inputError.push({
        type: "required",
        name: key,
        message: inputsErrors[key],
      });
    }
  });

  return inputError;
};

interface CompetitionRegisterProps {
  competition?: Competition;
  onRegister: () => void;
}

const CompetitionRegister: React.FC<CompetitionRegisterProps> = ({
  competition,
  onRegister,
}) => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!id) return;

    const errors = getErrors(data);

    if (errors.length) {
      errors.forEach((error) => {
        setError(error.name as keyof Inputs, {
          message: error.message,
          type: "required",
        });
      });
      return;
    }

    setIsLoading(true);
    const register = httpsCallable(functions, "competitionRegister");
    await register({
      competitionId: id,
      ...data,
      categoryName: competition?.resultCategories?.find(
        (item) => item.id === data.category
      )?.name,
    });

    setIsLoading(false);
    onRegister();
    // navigate(`/competition/${id}/participants`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {Object.values(errors).length ? (
        <div className={styles.errorContainer}>
          <Alert type="error">
            <ul className={styles.errorList}>
              {Object.values(errors).map((error, index) => (
                <li key={index}>{error.message}</li>
              ))}
            </ul>
          </Alert>
        </div>
      ) : null}

      <FormGroup>
        <div className={styles.row}>
          <div className={styles.col}>
            <TextInput
              label="Nombre y apellidos"
              hasError={!!errors.name}
              {...register("name")}
            />
          </div>
          <div className={styles.col}>
            <TextInput
              label="Licencia"
              hasError={!!errors.license}
              {...register("license")}
            />
          </div>
        </div>
      </FormGroup>

      <FormGroup>
        <div className={styles.row}>
          <div className={styles.col}>
            <TextInput
              label="Nombre del caballo"
              hasError={!!errors.horseName}
              {...register("horseName")}
            />
          </div>
          <div className={styles.col}>
            <div className={styles.row}>
              <div className={styles.col}>
                <TextInput
                  label="Licencia"
                  hasError={!!errors.horseLicense}
                  {...register("horseLicense")}
                />
              </div>
              <div className={styles.col}>
                <TextInput
                  label="Lic"
                  hasError={!!errors.horseLicense}
                  {...register("licNumber")}
                />
              </div>
            </div>
          </div>
        </div>
      </FormGroup>

      <FormGroup>
        <div className={styles.row}>
          <div className={styles.col}>
            <TextInput
              label="Correo electrónico"
              hasError={!!errors.email}
              {...register("email")}
            />
          </div>
          <div className={styles.col}>
            <TextInput
              label="Teléfono"
              hasError={!!errors.phone}
              {...register("phone")}
            />
          </div>
        </div>
      </FormGroup>

      <FormGroup>
        <Select
          {...register("category")}
          label="Prueba a realizar"
          hasError={!!errors.category}
          options={[
            {
              label: "Seleccionar categoría",
              value: "",
              disabled: true,
              selected: true,
            },
            ...(competition?.resultCategories?.map((category) => ({
              label: category.name,
              value: category.id,
            })) || []),
          ]}
        />
      </FormGroup>

      <FormGroup>
        <div className={styles.row}>
          <div className={styles.col}>
            <TextInput
              label="Nombre del club"
              hasError={!!errors.club}
              {...register("club")}
            />
          </div>
          <div className={styles.col}>
            <TextInput
              label="Código Rega"
              hasError={!!errors.regaCode}
              {...register("regaCode")}
            />
          </div>
        </div>
      </FormGroup>

      <Button submit block isLoading={isLoading}>
        Inscribirse
      </Button>
    </form>
  );
};

export { CompetitionRegister };
