import styles from "./styles.module.scss";

interface ColProps {
  className?: string;
  children?: React.ReactNode;
}

const Col: React.FC<ColProps> = ({ children, className }) => {
  return <div className={`${styles.col} ${className}`}>{children}</div>;
};

export { Col };
