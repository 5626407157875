import { forwardRef, useId } from 'react';
import styles from './styles.module.scss';
import { Label } from '..';

interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  hasError?: boolean;
  labelColor?: string;
  helpText?: string;
  onChangeText?: (text: string) => void;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      hasError,
      labelColor,
      helpText,
      className,
      onChangeText,
      ...otherProps
    },
    ref
  ) => {
    const textInputId = useId();

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      onChangeText && onChangeText(val);
    };

    return (
      <>
        {label && (
          <Label
            pointer
            color={hasError ? '#F44336' : labelColor}
            htmlFor={textInputId}
          >
            {label}
          </Label>
        )}
        <input
          ref={ref}
          onChange={handleOnChange}
          id={textInputId}
          className={`${styles.textInput} ${className} ${
            hasError && styles.hasError
          }`}
          {...otherProps}
        />
        {helpText && <div className={styles.helpText}>{helpText}</div>}
      </>
    );
  }
);

export { TextInput };
